<template>
  <div>
    <div class="z-10 flex justify-end sm:justify-between gap-4 items-center mb-2 p-1 md:px-0 relative">
      <div class="w-full sm:w-auto">
        <label for="search" class="sr-only">Quick search</label>
        <div class="relative flex items-center">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon class="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
          </div>
          <input
            id="search"
            ref="searchField"
            v-model="search"
            type="text"
            name="search"
            placeholder="Search"
            class="shadow-sm focus:ring-highlight-500 focus:border-highlight-500 block w-full pl-8 pr-12 sm:text-sm border-gray-300 rounded-md"
            @keydown.enter="unFocusField()"
            @input="searchHandler()" />
          <div class="hidden md:block absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
            <kbd class="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400"> {{ actionKey }}K </kbd>
          </div>
        </div>
      </div>
      <div class="flex space-x-2 sm:mt-0 self-end">
        <Popover as="div" class="relative inline-block text-left">
          <PopoverButton class="btn-sm btn-white flex">
            <FilterIcon class="md:-ml-1 md:mr-2 mx-0 h-6 w-6 sm:h-5 sm:w-5 text-gray-500" aria-hidden="true" />
            <span class="hidden md:block">Filter</span>
          </PopoverButton>
          <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0">
            <div>
              <PopoverPanel v-slot="{ close }" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                <div class="bg-gray-100 flex justify-between items-center p-1.5">
                  <button class="btn-xxs btn-white" @click="clearFilter(close)">Clear</button>
                  <span class="text-sm text-gray-700 font-medium">Filters</span>
                  <button class="btn-xxs btn-highlight" @click="saveFilter(close)">Save</button>
                </div>
                <div>
                  <Disclosure>
                    <DisclosureButton class="block w-full text-left px-3 py-2 text-sm">
                      <div class="relative flex items-start">
                        <div class="ml-2 text-sm self-center">
                          <label class="font-medium text-gray-700">Status</label>
                        </div>
                      </div>
                    </DisclosureButton>
                    <DisclosurePanel :static="true" class="bg-gray-50 px-4 py-2 border-t border-gray-100 space-y-1.5">
                      <div class="mx-auto w-full max-w-md">
                        <RadioGroup v-model="selectedStatus">
                          <div class="space-y-2">
                            <RadioGroupOption
                              v-for="status in statusItems"
                              :key="status.name"
                              as="template"
                              :value="status"
                              v-slot="{ active, checked }">
                              <div
                                :class="[
                                  active
                                    ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-highlight-100'
                                    : '',
                                  checked ? 'bg-highlight bg-opacity-75 text-white ' : 'bg-white ',
                                ]"
                                class="relative flex cursor-pointer rounded-lg px-2 py-1 shadow-md focus:outline-none">
                                <div class="flex w-full items-center justify-between">
                                  <div class="flex items-center">
                                    <div class="text-sm">
                                      <RadioGroupLabel
                                        as="p"
                                        :class="checked ? 'text-white' : 'text-gray-700'"
                                        class="font-medium">
                                        {{ status.name }}
                                      </RadioGroupLabel>
                                    </div>
                                  </div>
                                  <div v-show="checked" class="shrink-0 text-white">
                                    <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none">
                                      <circle
                                        cx="12"
                                        cy="12"
                                        r="12"
                                        fill="#fff"
                                        fill-opacity="0.2" />
                                      <path
                                        d="M7 13l3 3 7-7"
                                        stroke="#fff"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </RadioGroupOption>
                          </div>
                        </RadioGroup>
                      </div>
                    </DisclosurePanel>
                  </Disclosure>
                  <div v-role="['admin']">
                    <Disclosure>
                      <DisclosureButton class="block w-full text-left px-3 py-2 text-sm">
                        <div class="relative flex items-start">
                          <div class="ml-2 text-sm self-center">
                            <label class="font-medium text-gray-700">Host <span class="text-xs font-normal">(admin only)</span></label>
                          </div>
                        </div>
                      </DisclosureButton>
                      <DisclosurePanel class="bg-gray-50 px-4 py-2 border-t border-gray-100 space-y-1.5">
                        <Combobox v-model="selectedPerson">
                          <div class="relative mt-1">
                            <div
                              class="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                              <ComboboxInput
                                class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                :displayValue="(selectedPerson) => `${selectedPerson.first_name} ${selectedPerson.last_name}`"
                                @change="query = $event.target.value" />
                              <ComboboxButton
                                class="absolute inset-y-0 right-0 flex items-center pr-2">
                                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </ComboboxButton>
                            </div>
                            <TransitionRoot
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              @after-leave="query = ''">
                              <ComboboxOptions
                                class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <div
                                  v-if="filteredPeople.length === 0 && query !== ''"
                                  class="relative cursor-default select-none py-2 px-4 text-gray-700">
                                  Nothing found.
                                </div>

                                <ComboboxOption
                                  v-for="person in filteredPeople"
                                  :key="person.id"
                                  as="template"
                                  :value="person"
                                  v-slot="{ selected, active }">
                                  <li
                                    class="relative cursor-default select-none py-2 pl-10 pr-4"
                                    :class="{
                                      'bg-highlight text-white': active,
                                      'text-gray-700': !active,
                                    }">
                                    <span
                                      class="block truncate"
                                      :class="{ 'font-medium': selected, 'font-normal': !selected }">
                                      {{ `${person.first_name} ${person.last_name}` }}
                                    </span>
                                    <span
                                      v-if="selected"
                                      class="absolute inset-y-0 left-0 flex items-center pl-3"
                                      :class="{ 'text-white': active, 'text-lightGray': !active }">
                                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  </li>
                                </ComboboxOption>
                              </ComboboxOptions>
                            </TransitionRoot>
                          </div>
                        </Combobox>
                      </DisclosurePanel>
                    </Disclosure>
                  </div>
                </div>
              </PopoverPanel>
            </div>
          </transition>
        </Popover>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { SearchIcon, FilterIcon } from "@heroicons/vue/solid";
import {
  RadioGroup,
  RadioGroupOption,
  RadioGroupLabel,
  PopoverButton,
  Popover,
  PopoverPanel,
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot    } from "@headlessui/vue";
// import { ArrowSmRightIcon } from "@heroicons/vue/outline";
// import Multiselect from "@vueform/multiselect";
import debounce from "@/helpers/debounce";
import { useRoute, useRouter } from "vue-router";
import { useQuery } from "@vue/apollo-composable";
import { GET_VERIFIED_USERS } from "@/graphql/calendar/queries";
import { useStore } from "vuex";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "ListingSearchAndFiltering",
  components: {
    PopoverButton,
    Popover,
    PopoverPanel,
    SearchIcon,
    FilterIcon,
    // ArrowSmRightIcon
    // Multiselect,
    RadioGroup,
    RadioGroupOption,
    RadioGroupLabel,
    Combobox,
    ComboboxInput,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    TransitionRoot
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const search = ref('');

    const searchField = ref(null);

    const filterOpen = ref(false);

    const statusItems = ref([
      { name: 'Pending', value: 'PENDING' },
      { name: 'Published', value: 'PUBLISHED' },
      { name: 'Deleted', value: 'DELETED' },
      { name: 'Declined', value: 'DECLINED' },
      { name: 'Snoozed', value: 'SNOOZED' }
    ]);

    const selectedStatus = ref(statusItems.value.find(status => status.value === route.query.status));

    const {
      result,
      loading: loadingHosts,
      onResult
    } = useQuery(GET_VERIFIED_USERS, null, () => ({
      enabled: store.getters["auth/isAdmin"],
      fetchPolicy: "network-only"
    }));

    const hosts = computed(() => result.value.getVerifiedUsers ?? []);

    const selectedPerson = ref(null);
    const query = ref('')

    const filteredPeople = computed(() =>
      query.value === ''
        ? hosts.value
        : hosts.value.filter((host) =>
          `${host.first_name} ${host.last_name}`
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.value.toLowerCase().replace(/\s+/g, ''))
        )
    )

    onResult(() => {
      selectedPerson.value = hosts.value.find(host => host.id === +route.query.userId);
    });

    const actionKey = computed(() => {
      if (typeof navigator !== 'undefined') {
        if (/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
          return '⌘'
        }
        return 'Ctrl + '
      }
      return 'Ctrl + '
    });

    onMounted(() => {
      window.addEventListener("keydown", this.focusField);
      search.value = route.query.search;
    });

    onBeforeUnmount(() => {
      window.addEventListener("keydown", this.focusField);
    });

    const focusField = (e) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        searchField.value.focus();
      }
    };

    const unFocusField = () => {
      searchField.value.blur();
    };

    const searchHandler = () => {
      debounce(() => {
        router.push({ query: {
            ...route.query,
            page: undefined,
            search: search.value || undefined
        } })
      }, 600)
    };

    const saveFilter = (close) => {
      try {
        router.push({
          query: {
           ...route.query,
           page: undefined,
           search: search.value || undefined,
           status: selectedStatus.value?.value || undefined,
           userId: selectedPerson.value?.id || undefined
          }
        });
        close();
      } catch (err) {
        notify({
          title: err,
          type: 'error'
        })
      }
    }

    const clearFilter = (close) => {
      router.push({ query: {
          ...route.query,
          page: undefined,
          search: search.value || undefined,
          status: undefined,
          userId: undefined
      } });
      selectedStatus.value = null;
      selectedPerson.value = null;
      close();
    };

    return {
      search,
      searchField,
      statusItems,
      selectedStatus,
      actionKey,
      filterOpen,
      loadingHosts,
      hosts,
      selectedPerson,
      query,
      filteredPeople,
      focusField,
      unFocusField,
      searchHandler,
      saveFilter,
      clearFilter
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect-place {
  --ms-font-size: 0.8rem;
  --ms-line-height: 1.375;
  --ms-bg: #ffffff;
  --ms-bg-disabled: #f3f4f6;
  --ms-border-color: #d1d5db;
  --ms-border-width: 1px;
  --ms-radius: 4px;
  --ms-py: 0.5rem;
  --ms-px: 0.875rem;
  --ms-ring-width: 3px;
  --ms-ring-color: #10b98130;
  --ms-placeholder-color: #9ca3af;
  --ms-max-height: 10rem;

  --ms-spinner-color: #10b981;
  --ms-caret-color: #999999;
  --ms-clear-color: #999999;
  --ms-clear-color-hover: #000000;

  --ms-tag-font-size: 0.875rem;
  --ms-tag-line-height: 1.25rem;
  --ms-tag-font-weight: 600;
  --ms-tag-bg: #10b981;
  --ms-tag-bg-disabled: #9ca3af;
  --ms-tag-color: #ffffff;
  --ms-tag-color-disabled: #ffffff;
  --ms-tag-radius: 4px;
  --ms-tag-py: 0.125rem;
  --ms-tag-px: 0.5rem;
  --ms-tag-my: 0.25rem;
  --ms-tag-mx: 0.25rem;

  --ms-tag-remove-radius: 4px;
  --ms-tag-remove-py: 0.25rem;
  --ms-tag-remove-px: 0.25rem;
  --ms-tag-remove-my: 0rem;
  --ms-tag-remove-mx: 0.125rem;

  --ms-dropdown-bg: #ffffff;
  --ms-dropdown-border-color: #d1d5db;
  --ms-dropdown-border-width: 1px;
  --ms-dropdown-radius: 4px;

  --ms-group-label-py: 0.3rem;
  --ms-group-label-px: 0.75rem;
  --ms-group-label-line-height: 1.375;
  --ms-group-label-bg: #e5e7eb;
  --ms-group-label-color: #374151;
  --ms-group-label-bg-pointed: #d1d5db;
  --ms-group-label-color-pointed: #374151;
  --ms-group-label-bg-disabled: #f3f4f6;
  --ms-group-label-color-disabled: #d1d5db;
  --ms-group-label-bg-selected: #059669;
  --ms-group-label-color-selected: #ffffff;
  --ms-group-label-bg-selected-pointed: #0c9e70;
  --ms-group-label-color-selected-pointed: #ffffff;
  --ms-group-label-bg-selected-disabled: #75cfb1;
  --ms-group-label-color-selected-disabled: #d1fae5;

  --ms-option-font-size: 0.8rem;
  --ms-option-line-height: 1.375;
  --ms-option-bg-pointed: #ffffff;
  --ms-option-color-pointed: #1f2937;
  --ms-option-bg-selected: #10b981;
  --ms-option-color-selected: #ffffff;
  --ms-option-bg-disabled: #ffffff;
  --ms-option-color-disabled: #d1d5db;
  --ms-option-bg-selected-pointed: #26c08e;
  --ms-option-color-selected-pointed: #ffffff;
  --ms-option-bg-selected-disabled: #ffffff;
  --ms-option-color-selected-disabled: #d1fae5;
  --ms-option-py: 0.5rem;
  --ms-option-px: 0.75rem;

  --ms-empty-color: #4b5563;
}
</style>
