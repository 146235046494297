<template>
  <div class="col-span-12">
    <div class="border-b bg-white">
      <div class="max-w-4xl mx-auto py-2 px-3">
        <div class="flex justify-between items-center gap-4">
          <h1 class="md:text-xl font-medium leading-7 text-gray-800 sm:text-2xl sm:truncate">Listings</h1>
          <router-link class="btn-xs btn-highlight flex" :to="{ name: 'general-create' }">
            <PlusSmIcon class="-ml-1 mr-2 h-6 w-6 text-white" aria-hidden="true" />
            <span>Create listing</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="bg-gray-100">
      <div class="max-w-4xl mx-auto px-4 pb-10 py-4 lg:pb-24 lg:px-8">
        <!-- ADMIN filtering section START -->
        <div class="border-b-2 mb-4">
          <div>
            <ListingSearchAndFiltering />
          </div>
        </div>
        <!-- ADMIN filtering section END -->
        <button v-if="!products" type="button" class="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400">
          <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6" />
          </svg>
          <span class="mt-2 block text-sm font-medium text-gray-900"> Create a listing now </span>
        </button>
        <div v-if="$apollo.queries.productsList.loading" class="loading-state flex justify-center py-20 bg-gray-800 bg-opacity-5 rounded-lg">
          <LoadingSpinner outer-class="h-10 h-10 text-water" />
        </div>
        <div v-else>
          <div>
            <ul v-if="products" role="list" class="space-y-2">
              <ProductCard v-for="product in products" :key="product.id" :product="product" />
            </ul>
            <div v-if="products.length === 0">
              <EmptyTable @reset-filters="resetFilters" />
            </div>
          </div>
          <div v-if="total > 8" class="mt-4">
            <Pagination
              :totalResults="total"
              :perPage="8"
              :page="$route.query.page || 1"
              label="listings" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/UI/Navigation/Pagination";
import LoadingSpinner from "@/components/UI/Indication/LoadingSpinner";
import ProductCard from "@/components/Product/ProductCard";
import { GET_PRODUCTS } from "@/graphql/products/queries";
import EmptyTable from "@/components/UI/EmptyStates/EmptyTable";
import ListingSearchAndFiltering from "@/components/views/Listing/ListingSearchAndFiltering";

export default {
  name: "Listings",
  components: {
    ListingSearchAndFiltering,
    EmptyTable,
    ProductCard,
    LoadingSpinner,
    Pagination
  },
  apollo: {
    productsList: {
      query: GET_PRODUCTS,
      update: data => data.getProducts,
      variables() {
        return {
          page: +this.$route.query.page || 1,
          perPage: 8,
          sortCreatedAt: -1,
          status: this.$route.query.status || null,
          search: this.$route.query.search || "",
          userId: +this.$route.query.userId || null
        }
      },
      fetchPolicy: 'network-only',
      result(products) {
        const { data: { getProducts: { total, items } } } = products;
        this.total = total;
        const status = (productStatus) => {
          if (productStatus === 'published') {
            return  {
              text: 'Published',
              styles: 'bg-green-100 text-green-800'
            }
          }
          if (productStatus === 'declined') {
            return  {
              text: 'Declined',
              styles: 'bg-red-100 text-red-500'
            }
          }
          if (productStatus === 'deleted') {
            return  {
              text: 'Deleted',
              styles: 'bg-red-100 text-red-500'
            }
          }
          if (productStatus === 'pending') {
            return {
              text: 'Pending',
              styles: 'bg-yellow-100 text-yellow-400'
            }
          }
          if (productStatus === 'snoozed') {
            return {
              text: 'Snoozed',
              styles: 'bg-blue-100 text-blue-400'
            }
          }

          return null;
        };
        
        this.products = items.map((product) => ({
          id: product.id,
          title: product.translation.title,
          imageUrl: `${process.env.VUE_APP_IMGIX_URL}/${product.media?.media.path}`,
          rating: product.rating,
          reviews: product.reviews_count,
          priceFrom: Math.round(product.default_price),
          currency: this.currencies.find(currency => currency.id === product.currency).code,
          status: status(product.status),
          type: product.categories[0].translation.title,
          location: product.address
        }))
      }
    }
  },
  data() {
    return {
      products: [],
      total: null
    }
  },
  methods: {
    resetFilters() {
      this.$router.push({ query: null });
    }
  },
  computed: {
    currencies() {
      return this.$store.getters['product/getCurrencies']
    }
  }
};
</script>
