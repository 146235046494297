<template>
  <li>
    <router-link
      :to="{ name: 'general', params: { id: product.id }}"
      class="block bg-white border rounded-lg hover:border hover:border-gray-800
      duration-200 hover:md:-mx-1 hover:shadow-lg relative">
      <div class="flex flex-col sm:flex-row">
        <div class="absolute top-2 right-2">
          <span
            class="block md:mb-1 inline-flex items-center px-1.5 py-0.5 rounded-full text-xs
                  font-medium capitalize"
            :class="product.status.styles">
            {{ product.status.text }}
          </span>
        </div>
        <div class="w-full sm:w-36 sm:-m-px flex-shrink-0">
          <div class="rounded-t-lg sm:rounded-l-lg sm:rounded-tr-none aspect-w-14 aspect-h-6 sm:aspect-w-8 sm:aspect-h-6 bg-gray-100">
            <img
              :src="`${product.imageUrl}?fm=jp2&w=768&h=768&fit=max&auto=format,compress`"
              class="rounded-t-lg sm:rounded-l-lg sm:rounded-tr-none object-cover" />
          </div>
        </div>
        <div class="p-4 flex-1 overflow-hidden">
          <div class="sm:flex sm:flex-row-reverse items-center justify-between">
            <div v-if="product.status" class="absolute top-2 right-2 sm:relative flex ml-2 flex-shrink-0">
              <p
                class="px-2 hidden text-xs leading-5 font-medium rounded-full"
                :class="product.status === 'Closed' ? 'bg-red-100 text-red-800' :
                  product.status === 'hidden' ? 'bg-gray-100 text-gray-700' : 'bg-green-100 text-green-800'">
                {{ product.status }}
              </p>
            </div>
            <div class="flex-wrap">
              <p class="text-lg font-medium text-starryNight md:truncate">
                {{ product.title }}
              </p>
            </div>
          </div>
          <div class="mt-2 sm:flex sm:justify-between">
            <div class="sm:flex">
              <div class="flex items-center gap-2">
                <div class="inline-flex items-center border rounded-md px-2 py-0.5">
                  <span class="block text-sm font-medium text-gray-600">{{ product.priceFrom }}</span>
                  <span class="block text-sm text-gray-500 ml-1">{{ product.currency }}</span>
                </div>
                <div class="inline-flex items-center border rounded-md px-2 py-0.5">
                  <p class="text-sm text-gray-600">
                    {{ product.type }}
                  </p>
                </div>
              </div>
              <p class="mt-2 hidden sm:flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                <LocationMarkerIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                {{ product.location }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </li>
</template>

<script>
import { LocationMarkerIcon } from '@heroicons/vue/solid';

export default {
  name: "ProductCard",
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  components: {
    LocationMarkerIcon
  }
};
</script>

<style scoped></style>
